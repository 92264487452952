
import { Component } from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import { investmentSubjectOperationsModule } from '@/modules/investments/shared/state/submodules';
import { visualizationViewsModule } from '@/modules/visualizations/shared/state/submodules';
import { VisualizationView } from '@/modules/visualizations/shared/models/visualization-view';
import { mdiFloorPlan, mdiTooltipImageOutline } from '@mdi/js';
import PreventUnload from '@/shared/PreventUnload.vue';
import FormComponent from '@/shared/components/layout/form/form-component';
import { VisualizationAreaDataRequest } from '@/modules/visualizations/shared/requests/visualization-area-data-request';
import AutoCompleteWithAddNew from '@/shared/components/elements/auto-complete-with-add-new.vue';
import ViewForm from '@/modules/visualizations/components/views/form.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {
        FormActions,
        PreventUnload,
        AutoCompleteWithAddNew,
    },
})
export default class AreaForm extends FormComponent<VisualizationAreaDataRequest> {
    public store = this.$store.state.visualizationsState.viewsState;
    public storeAction = '';
    public updateAction = '';
    public ViewForm = ViewForm;
    public viewsModule = visualizationViewsModule;
    public requestData = new VisualizationAreaDataRequest();
    public viewsTypes = visualizationViewsModule.actionsTypes;
    public subjectTypes = investmentSubjectOperationsModule.actionsTypes;
    public areaTypes = [
        { name: 'Przedmiot inwestycji', value: 'investment-subject', icon: mdiFloorPlan },
        { name: 'Widok', value: 'view', icon: mdiTooltipImageOutline },
    ];

    get formTitle() {
        return this.edit ? 'Edytuj obszar' : 'Dodaj obszar';
    }

    public created() {
        const filters = {
            investment_id: [this.view.investment_id],
            investment_object_id: [this.view.investment_object_id],
        };
        this.$store.dispatch(this.subjectTypes.FETCH_DATA, { filters });
        this.$store.dispatch(this.viewsTypes.FETCH_DATA, {
            simple: true,
            filters: {
                visualization_id: this.view.visualization_id,
            },
        });
    }

    public async submitForm() {
        await this.form.validate();
        if (this.valid) {
            this.$emit('saved', this.requestParams);
        }
    }

    public initForm() {
        this.form = this.$refs.createAreaForm;
         }

    get view() {
        return this.$store.state.visualizationsState.viewsState.editedItemEditor;
    }

    get views() {
        return this.$store.state.visualizationsState.viewsState.dataList.filter(
            (item: VisualizationView) => item.id !== this.view.id,
        );
    }

    public itemDisabled(item: any) {
        return !this.view.investment_object_id && item.value === 'investment-subject';
    }

    get viewsLoading() {
        return this.$store.state.visualizationsState.viewsState.loadingList;
    }

    get subjects() {
        return this.$store.state.investmentsState.subjectOperationsState.data;
    }

    get subjectsLoading() {
        return this.$store.state.investmentsState.subjectOperationsState.loading;
    }
}
