
import { Component, Watch } from 'vue-property-decorator';
import { mdiCodeTags, mdiShare, mdiShareOff } from '@mdi/js';
import AddressField from '@/shared/components/elements/address.vue';
import DetailsComponent from '@/shared/components/layout/details/details-component';
import { ModuleProps } from '@/shared/state/template/module-props';
import TopToolbar from '@/shared/components/layout/details/top-toolbar.vue';
import { Visualization } from '@/modules/visualizations/shared/models/visualization';
import { visualizationsModule } from '@/modules/visualizations/shared/state/module';
import ListToolbar from '@/shared/components/layout/list/list-toolbar.vue';
import VisualizationViewsList from '@/modules/visualizations/components/views/list.vue';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import Vue from 'vue';
import { visualizationViewsModule } from '@/modules/visualizations/shared/state/submodules';
import { RawLocation } from 'vue-router';

@Component({
    props: {
        id: String,
    },
    components: {
        ListToolbar,
        TopToolbar,
        ActionConfirmDialog,
        VisualizationViewsList,
        AddressField,
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            vm.backRoute = from;
        });
    },
})
export default class VisualizationDetails extends DetailsComponent<Visualization> {
    public icons: object = {
        mdiCodeTags,
        mdiShare,
        mdiShareOff,
    };
    public image: HTMLImageElement | null = null;
    public props: ModuleProps = visualizationsModule.moduleProps;
    public actionsTypes = visualizationsModule.actionsTypes;
    public mutationsTypes = visualizationsModule.mutationsTypes;
    public viewMutationsTypes = visualizationViewsModule.mutationsTypes;
    public showAction = this.actionsTypes.SHOW_ITEM;
    public previewFrame: HTMLIFrameElement | null = null;
    public viewLoading: boolean = false;
    public store = this.$store.state.visualizationsState;
    public backRoute!: RawLocation;
    public backgroundNotFound = false;
    public currentUrl = '';

    get changingVisibility() {
        return this.$store.state.visualizationsState.changingVisibility;
    }

    get shareDialog() {
        return this.$store.state.visualizationsState.shareDialog;
    }

    set shareDialog(value) {
        this.$store.commit(this.mutationsTypes.UPDATE_SHARE, value);
    }

    get codeDialog() {
        return this.$store.state.visualizationsState.codeDialog;
    }

    set codeDialog(value) {
        this.$store.commit(this.mutationsTypes.UPDATE_CODE, value);
    }

    get currentView() {
        return this.store.viewsState.currentView;
    }

    public created() {
        this.fetchDetails();
    }

    public goBack() {
        this.$router.replace(this.backRoute ?? { name: 'visualizations-list' });
    }

    public isPermitted(action: string) {
        return this.permissionCheck(`visualizations.${action}`);
    }

    public viewChanged(e: any) {
        this.$nextTick(() => {
            this.$store.commit(this.viewMutationsTypes.UPDATE_VIEW, e.detail);
        });
    }

    public mounted() {
        this.previewFrame = this.$refs.previewFrame as HTMLIFrameElement;
        window.addEventListener('noBackground', this.noBackground);
        window.addEventListener('viewChange', this.viewChanged);
        window.addEventListener('viewLoaded', () => {
            this.viewLoading = false;
        });
    }

    public reloadFrame() {
        this.backgroundNotFound = false;
        if (this.previewFrame && this.previewFrame.contentWindow) {
            this.previewFrame.contentWindow.location.reload();
        }
    }

    public setActive() {
        this.$store
            .dispatch(this.actionsTypes.CHANGE_VISIBILITY, { id: this.id, active: !this.item.active })
            .then((response) => {
                if (response.data) {
                    if (response.data.active) {
                        this.shareDialog = true;
                    }
                    this.$store.commit(this.mutationsTypes.UPDATE_CURRENT, response.data);
                }
            });
    }

    public fetchCallback({ data }: ModuleShowPayload<Visualization>) {
        if (data) {
            this.viewChanged({ detail: data.visualization_view_id });
        }
        this.updateUrl();
    }

    public updateUrl() {
        this.currentUrl = ``;
        this.$nextTick(() => {
            this.currentUrl = `/vs/${this.item.uuid}/`;
            if (this.currentView > 0) {
                this.currentUrl += this.currentView;
            }
            if (this.currentView === null) {
                this.viewLoading = false;
            }
        });
    }

    get HTMLCode() {
        return `<iframe width="640" height="360" src="https://${window.location.hostname}/vs/${this.item.uuid}/" style="border: 1px solid #ccc; border-radius: 5px; box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.25);"title="${this.item.name}"/>`;
    }

    public noBackground() {
        this.backgroundNotFound = true;
    }

    public copyCode() {
        const htmlCode = this.$refs.htmlCode as Vue;
        const textToCopy: HTMLTextAreaElement = htmlCode.$el.querySelector('textarea') as HTMLTextAreaElement;

        if (textToCopy) {
            textToCopy.select();
            document.execCommand('copy');
            this.$store.commit('SHOW_SNACKBAR', { type: 'success', text: 'Skopiowano do schowka' });
        }
    }

    @Watch('currentView')
    public onViewChange() {
        this.backgroundNotFound = false;
    }
}
