

import {Component, Prop, Watch} from 'vue-property-decorator';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import FormComponent from '@/shared/components/layout/form/form-component';
import {investmentObjectsModule} from '@/modules/investments/shared/state/submodules';
import SelectTree from '@/shared/components/elements/select-tree.vue';
import {VisualizationViewDataRequest} from '@/modules/visualizations/shared/requests/visualization-view-data-request';
import {visualizationViewTypes} from '@/shared/config/visualizations';
import {visualizationViewsModule} from '@/modules/visualizations/shared/state/submodules';
import MediaFileInput from '@/modules/media/components/media-file-input.vue';
import {callSuper, sortItemsTree} from '@/shared/helpers';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import Hint from '@/shared/components/elements/hint.vue';

@Component({
    components: {MediaFileInput, SelectTree, FormActions, Hint},
})
export default class ViewForm extends FormComponent<VisualizationViewDataRequest> {
    @Prop(Boolean) public backgroundCorrupted!: boolean;
    public actionsTypes = visualizationViewsModule.actionsTypes;
    public requestData = new VisualizationViewDataRequest();
    public store = this.$store.state.visualizationsState.viewsState;
    public investmentId = this.$store.state.visualizationsState.current.investment_id;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public viewTypes = visualizationViewTypes;
    public objectsTypes = investmentObjectsModule.actionsTypes;
    public item: any = null;
    public mediaError: string = '';

    public filters = {
        extension: [
            'gif',
            'jpeg',
            'jpg',
            'bmp',
            'png',
        ]
    };

    public mounted() {
        this.setTarget();
        this.form = this.$refs.createViewForm;
        this.itemData.investment_id = this.investmentId;
        this.$store.dispatch(this.objectsTypes.FETCH_DATA, {filters: {investment_id: this.investmentId}, simple: true});
    }

    get objects() {
        return this.$store.state.investmentsState.objectsState.dataList;
    }

    public nameChange(id: number) {
        this.clearError('investment_object_id');
        if (!this.itemData.name || (
            this.itemData.name &&
            sortItemsTree(this.objects).find((item) => item.name === this.itemData.name)
        )) {
            this.$set(this.itemData, 'name', sortItemsTree(this.objects).find((item) => item.id === id).name);
        }
    }

    public setBackground(e: MediaFile) {
        if (e) {
            this.clearError('media_file_id');
            this.itemData.media_file_id = e.id;
        }
    }

    public targetChange() {
        if (this.itemData.target_type !== 'object' && !this.edit) {
            delete this.itemData.investment_object_id;
        }
    }

    @Watch('show')
    public onShowChange() {
        this.setTarget();
    }

    @Watch('item')
    public onItemChange() {
        this.itemData.media_file_id = this.item;
    }

    @Watch('itemData.area_color')
    public ifColorObject() {
        if (!(typeof this.itemData.area_color === 'string')) { this.itemData.area_color = '#147BD1'; }
    }

    public setTarget() {
        if (this.show && this.itemData.investment_object_id) {
            this.$set(this.itemData, 'target_type', 'object');
        } else {
            this.$set(this.itemData, 'target_type', 'investment');
        }
    }

    get objectsLoading() {
        return this.$store.state.investmentsState.objectsState.loadingList;
    }

    get formTitle() {
        return this.edit ? 'Edytuj widok' : 'Dodaj widok';
    }

    public async submitForm(e: any, next?: boolean): Promise<void> {
        if (this.itemData.target_type !== 'object' && this.edit) {
            delete this.itemData.investment_object_id;
        }

        if (this.item === undefined) {
            this.mediaError = 'Uzupełnij tło widoku';
            return this.errorMessages.media_file_id;
        } else {
            this.itemData.media_file_id = this.item.id;
        }

        return callSuper(this, 'submitForm', e, next);
    }

    public created() {
        //
    }
}

