
import {Component} from 'vue-property-decorator';
import {visualizationsListHeaders} from '@/modules/visualizations/shared/config';
import VisualizationDetails from '@/modules/visualizations/components/details.vue';
import VisualizationForm from '@/modules/visualizations/components/form.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {VisualizationDataRequest} from '@/modules/visualizations/shared/requests/visualization-data-request';
import {visualizationsModule} from '@/modules/visualizations/shared/state/module';
import {Visualization} from '@/modules/visualizations/shared/models/visualization';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';

@Component({
    components: {
        VisualizationDetails,
        VisualizationForm,
        ItemsTable,
    },
})
export default class VisualizationsList extends ListComponent<Visualization, VisualizationDataRequest> {
    public headers: object[] = visualizationsListHeaders;
    public actionsTypes = visualizationsModule.actionsTypes;
    public mutationTypes = visualizationsModule.mutationsTypes;
    public props: ModuleProps = visualizationsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.visualizationsState;
}
