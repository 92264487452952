
import {Component, Vue} from 'vue-property-decorator';
import Konva from 'konva';
import AreaForm from '@/modules/visualizations/components/views/areas/form.vue';
import {findLastId} from '@/modules/investments/components/generator/helpers';
import {VisualizationView} from '@/modules/visualizations/shared/models/visualization-view';
import SelectMediaList from '@/modules/media/components/select.vue';
import ViewForm from '@/modules/visualizations/components/views/form.vue';
import {visualizationViewsModule} from '@/modules/visualizations/shared/state/submodules';
import {ViewsState} from '@/modules/visualizations/shared/state/state/views';
import {VisualizationViewDataRequest} from '@/modules/visualizations/shared/requests/visualization-view-data-request';
import {angle, centroid, pointsDistance, shapeRefresh} from '@/modules/visualizations/shared/helpers';
import {VisualizationAreaDataRequest} from '@/modules/visualizations/shared/requests/visualization-area-data-request';
import KonvaEventObject = Konva.KonvaEventObject;

@Component({
    components: {
        AreaForm,
        ViewForm,
        SelectMediaList,
    },
    props: {
        itemData: Object,
    },
})
export default class ViewEditor extends Vue {
    // Props
    public itemData!: VisualizationViewDataRequest;

    // Store
    public store: ViewsState = this.$store.state.visualizationsState.viewsState;
    public actionsTypes = visualizationViewsModule.actionsTypes;
    public mutationsTypes = visualizationViewsModule.mutationsTypes;

    // Draw Helpers
    public cursorX = 0;
    public cursorY = 0;
    public drawStraight = false;
    public backgroundLoading: boolean = false;
    public lastAngle = 0;
    public changeHistoryIndex: number = -1;

    // Dialogs
    public viewFormDialog = false;
    public newAreaDialog = false;
    public layersMenu = false;

    public editedArea = {} as VisualizationAreaDataRequest;
    public editedView = {} as VisualizationViewDataRequest;
    public straightHelper: boolean = false;

    // Cursor state Helpers
    public activeSelect = false;
    public isDragging = false;
    public isDraggingShape = false;


    public canvasWidth = window.innerWidth;
    public canvasHeight = window.innerHeight;
    public canvasScale = 0.5;

    public selectedShapeIndex = -1;
    public currentPoint = -1;

    public pointContextMenu = false;
    public shapeContextMenu = false;
    public menuX = 0;
    public menuY = 0;
    public offsetMenuX = 0;
    public offsetMenuY = 0;

    public scaleBy = 0.79;
    public background: HTMLImageElement = new Image();

    get noBackground() {
        return this.background.src === '';
    }

    get changeHistory() {
        return this.store.changeHistory;
    }

    set changeHistory(value) {
        this.$store.commit(this.mutationsTypes.UPDATE_HISTORY, value);
    }

    get saving() {
        return this.store.loadingItem;
    }

    get error() {
        return this.store.error;
    }

    public cleanError() {
        this.$store.commit(this.mutationsTypes.UPDATE_ERROR_TITLE, '');
    }

    public viewFormCanceled() {
        if (this.noBackground) {
            this.$store.commit(this.mutationsTypes.UPDATE_EDITOR_DIALOG, false);
        }

        this.viewFormDialog = false;
    }

    public saveView() {
        const itemData = JSON.parse(JSON.stringify(this.itemData));
        itemData.areas.map((item: VisualizationAreaDataRequest) => {
            const area = {...item};
            area.points.map((point) => {
                point.x += area.offsetX;
                point.y += area.offsetY;
            });
            area.offsetX = 0;
            area.offsetY = 0;
            return area;
        });
        this.$store.dispatch(this.actionsTypes.UPDATE_ITEM, itemData).then((response) => {
            if (response) {
                this.$emit('saved');
            }
        });
    }

    public duplicateArea() {
        const area: VisualizationAreaDataRequest = JSON.parse(JSON.stringify(
            this.itemData.areas[this.selectedShapeIndex],
        ));
        area.id = findLastId(this.itemData.areas) + 1;
        area.name += ' (Kopia)';
        const yPoints = area.points.map(({y}) => y);
        area.points = area.points.map((point: { x: number, y: number }) => {
            point.x += area.offsetX;
            point.y += area.offsetY;
            point.y += (Math.max(...yPoints) - Math.min(...yPoints));
            return point;
        });
        area.offsetX = 0;
        area.offsetY = 0;
        this.itemData.areas.splice(this.selectedShapeIndex + 1, 0, area);
        this.onItemDataChange();
    }

    public handlePointDrag(e: KonvaEventObject<DragEvent>, index: number) {
        if (!this.activeSelect) {
            this.straightHelper = e.evt.shiftKey;
            this.isDragging = true;
            const position = this.stage.getPointerPosition();

            const points: any = this.itemData.areas[this.selectedShapeIndex].points;
            if (position) {
                this.cursorX = ((position.x - this.stage.x()) / this.canvasScale);
                this.cursorY = ((position.y - this.stage.y()) / this.canvasScale);
                points[index].x = (
                    (this.cursorX - this.itemData.areas[this.selectedShapeIndex].offsetX)
                );
                points[index].y = (
                    (this.cursorY - this.itemData.areas[this.selectedShapeIndex].offsetY)
                );
            }
            this.$set(this.itemData.areas[this.selectedShapeIndex], 'points', points);

            this.stage.batchDraw();
        }
    }

    public editView() {
        this.editedView = {...this.itemData};
        this.viewFormDialog = true;
    }

    public viewSaved(e: VisualizationView) {
        this.itemData = this.editedView;
        this.viewFormDialog = false;
        if (this.noBackground) {
            this.saveView();
        }
    }

    public moveStage(e: KonvaEventObject<MouseEvent>) {
        if (e.evt) {
            switch (e.evt.button) {
                case 0:
                    if (!e.target.className) {
                        this.stage.stopDrag();
                    }
                    break;
                case 1:
                    this.stage.startDrag();
                    break;
            }
        }
    }

    public zoom(e: KonvaEventObject<WheelEvent>, zoom: boolean = true) {
        e.evt.preventDefault();

        const pointer = this.stage.getPointerPosition();
        if (pointer &&
            (
                (e.evt.deltaY > 0 || (this.canvasScale <= (this.background.width / this.canvasWidth) * 100)) &&
                (e.evt.deltaY < 0 || this.canvasScale >= 0.5)
            )
        ) {
            const oldScale = Number(this.canvasScale);

            const mousePointTo = {
                x: (pointer.x - this.stage.x()) / oldScale,
                y: (pointer.y - this.stage.y()) / oldScale,
            };

            if (zoom) {
                this.shapeContextMenu = false;
                this.pointContextMenu = false;
                this.canvasScale = e.evt.deltaY > 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
            }

            const newPos = {
                x: pointer.x - mousePointTo.x * this.canvasScale,
                y: pointer.y - mousePointTo.y * this.canvasScale,
            };
            this.stage.position(newPos);
            this.stage.batchDraw();
        }
    }

    public handleShapeDrag(event: KonvaEventObject<DragEvent>, index: number) {
        this.selectedShapeIndex = index;
        this.isDraggingShape = true;
        this.itemData.areas[this.selectedShapeIndex].offsetX = event.target.x();
        this.itemData.areas[this.selectedShapeIndex].offsetY = event.target.y();
    }

    public removeArea(index: number) {
        this.itemData.areas.splice(index, 1);
        this.itemData.areas.map((item: VisualizationAreaDataRequest) => {
            const shape = this.shapesLayer.findOne(`#shape_${item.id}`);
            if (shape) {
                shape.x(item.offsetX);
                shape.y(item.offsetY);
                shape.draw();
            }
        });
        this.onItemDataChange();
        this.selectedShapeIndex = -1;
    }

    public newArea() {
        this.editedArea = {} as VisualizationAreaDataRequest;
        this.editedArea.points = [];
        this.editedArea.offsetX = 0;
        this.editedArea.offsetY = 0;
        this.newAreaDialog = true;
    }

    public editArea(item: VisualizationAreaDataRequest) {
        this.editedArea = {...item};
        this.newAreaDialog = true;
    }

    public openPointMenu(e: KonvaEventObject<MouseEvent>, index: number) {
        this.menuX = e.evt.clientX;
        this.menuY = e.evt.clientY;
        this.pointContextMenu = true;
        this.currentPoint = index;
    }

    public openShapeMenu(e: KonvaEventObject<any>, index: number) {
        if (!this.activeSelect && !this.pointContextMenu) {
            const pointer = this.stage.getPointerPosition();
            if (pointer) {
                this.menuX = e.evt.changedTouches ? e.evt.changedTouches[0].clientX : e.evt.clientX;
                this.menuY = e.evt.changedTouches ? e.evt.changedTouches[0].clientY : e.evt.clientY;
                this.offsetMenuX = (pointer.x - this.stage.x()) / this.canvasScale;
                this.offsetMenuY = (pointer.y - this.stage.y()) / this.canvasScale;
                this.shapeContextMenu = true;
                this.selectedShapeIndex = index;
            }
        }
    }

    public removePoint() {
        this.itemData.areas[this.selectedShapeIndex].points.splice(this.currentPoint, 1);
    }

    public addArea(item: VisualizationAreaDataRequest) {
        const editData: any = {...item};
        if (editData.id > 0) {
            this.itemData.areas.splice(this.itemData.areas.map((e) => e.id).indexOf(this.editedArea.id), 1, editData);
        } else {
            editData.id = findLastId(this.itemData.areas) + 1;
            this.itemData.areas.push(editData);
            this.activeSelect = true;
        }
        this.selectedShapeIndex = this.itemData.areas.length - 1;
        this.newAreaDialog = false;
    }

    public isCloseToStartPoint(position: Konva.Vector2d, distance: number = 3) {
        return (this.currentPoints.length > 0) && (pointsDistance(position, this.currentPoints[0]) < distance);
    }

    public cursorMove({evt}: KonvaEventObject<MouseEvent>) {
        if (this.activeSelect) {
            const position = this.stage.getPointerPosition();
            if (position) {
                const cursorX = (position.x - this.stage.x()) / this.canvasScale;
                const cursorY = (position.y - this.stage.y()) / this.canvasScale;
                if (!this.straightHelper && evt.ctrlKey) {
                    this.straightHelper = true;
                }

                if (!evt.ctrlKey && !evt.shiftKey) {
                    this.cursorX = cursorX;
                    this.cursorY = cursorY;
                    this.straightHelper = false;

                }
                if (evt.shiftKey) {
                    if (!this.drawStraight) {
                        this.lastAngle = angle(
                            cursorX,
                            cursorY,
                            this.currentPoints[this.currentPoints.length - 1].x || 0,
                            this.currentPoints[this.currentPoints.length - 1].y || 0,
                        );
                        this.cursorY = cursorY;
                        this.cursorX = cursorX;
                    }
                    if ([145, 45, -45, -145].includes(this.lastAngle)) {
                        this.cursorX = this.currentPoints[this.currentPoints.length - 1].x || cursorX;
                        this.cursorY = this.currentPoints[this.currentPoints.length - 1].y || cursorY;
                    }
                    const distance = pointsDistance(this.currentPoints[this.currentPoints.length - 1], {
                        x: cursorX,
                        y: cursorY,
                    });
                    switch (this.lastAngle) {
                        case 90:
                            this.drawStraight = true;
                            this.cursorY = cursorY;
                            break;
                        case -90:
                            this.drawStraight = true;
                            this.cursorY = cursorY;
                            break;
                        case 180:
                            this.drawStraight = true;
                            this.cursorX = cursorX;
                            break;
                        case 0:
                            this.drawStraight = true;
                            this.cursorX = cursorX;
                            break;
                        case 145:
                            this.drawStraight = true;
                            this.cursorX += ((distance) / Math.sqrt(2));
                            this.cursorY -= ((distance) / Math.sqrt(2));
                            break;
                        case 45:
                            this.drawStraight = true;
                            this.cursorX -= ((distance) / Math.sqrt(2));
                            this.cursorY -= ((distance) / Math.sqrt(2));
                            break;
                        case -45:
                            this.drawStraight = true;
                            this.cursorX -= ((distance) / Math.sqrt(2));
                            this.cursorY += ((distance) / Math.sqrt(2));
                            break;
                        case -145:
                            this.drawStraight = true;
                            this.cursorX += ((distance) / Math.sqrt(2));
                            this.cursorY += ((distance) / Math.sqrt(2));
                            break;
                    }
                    if (this.isCloseToStartPoint({x: cursorX, y: cursorY})) {
                        this.cursorX = this.currentPoints[0].x || cursorX;
                        this.cursorY = this.currentPoints[0].y || cursorY;
                    }
                } else {
                    this.drawStraight = false;
                }
                this.stage.batchDraw();
            }
        }
    }

    public selectArea(e: KonvaEventObject<MouseEvent>) {
        const position = this.stage.getPointerPosition();
        if (position) {
            switch (e.evt.button) {
                case 0:
                    if (this.selectedShapeIndex >= 0) {
                        if (this.activeSelect && !this.isCloseToStartPoint({
                            x: (((position.x - this.stage.x()) / this.canvasScale)),
                            y: (((position.y - this.stage.y()) / this.canvasScale)),
                        }, 10)) {
                            this.drawStraight = false;
                            this.itemData.areas[this.selectedShapeIndex].points.push({
                                x: (this.cursorX - this.itemData.areas[this.selectedShapeIndex].offsetX),
                                y: (this.cursorY - this.itemData.areas[this.selectedShapeIndex].offsetY),
                            });
                            this.onItemDataChange();
                            this.stage.batchDraw();
                        } else {
                            this.straightHelper = false;
                            this.activeSelect = this.currentPoints.length < 3;
                        }
                    }
                    break;
                case 1:
                    this.moveStage(e);
            }
        }
    }

    public pointsWithOffset(points: Array<{ x: number, y: number }>, offsetX: number, offsetY: number) {
        return points.map(({x, y}) => {
            return {
                x: x + offsetX,
                y: y + offsetY,
            };
        });
    }

    get areaTitles(): Konva.TextConfig[] {
        return this.itemData.areas ? this.itemData.areas.map((area: VisualizationAreaDataRequest) => {
            const points = this.pointsWithOffset([...area.points], area.offsetX, area.offsetY);
            return {
                text: area.name,
                fontSize: 10,
                offsetX: (area.name.length * 2) / (window.innerWidth / this.canvasWidth),
                ...centroid(points),
            };
        }) : [];
    }

    get currentPoints(): Konva.CircleConfig[] {
        return (this.selectedShapeIndex >= 0 &&
            this.itemData.areas[this.selectedShapeIndex] &&
            this.itemData.areas[this.selectedShapeIndex].points.length > 0
        ) ? this.itemData.areas[this.selectedShapeIndex].points.map((e: { x: number, y: number }, index: number) => {
            return {
                x: e.x + this.itemData.areas[this.selectedShapeIndex].offsetX,
                y: e.y + this.itemData.areas[this.selectedShapeIndex].offsetY,
                radius: 2 * (this.background.width / window.innerWidth),
                fill: (index === 0) ?
                    '#0f0' :
                    (this.activeSelect && index === this.itemData.areas[this.selectedShapeIndex].points.length - 1 ?
                            '#f00' :
                            '#ccc'
                    ),
                stroke: 'black',
                draggable: !this.activeSelect,
                strokeWidth: 0.5 * (this.background.width / window.innerWidth),
            } as Konva.CircleConfig;
        }) : [];
    }

    get shapes(): Konva.LineConfig[] {
        return this.itemData.areas ? this.itemData.areas.map((e, index) => {
            const item = {...e};
            const points: number[] = [];
            item.points.forEach((point: { x: number, y: number }) => {
                points.push(point.x, point.y);
            });
            return {
                ...item,
                stroke: this.selectedShapeIndex === index ? this.$vuetify.theme.currentTheme.primary : 'black',
                fill: this.itemData.area_color,
                closed: this.selectedShapeIndex < 0 || (!this.activeSelect || this.selectedShapeIndex !== index),
                opacity: 0.65,
                strokeWidth: 0.5,
                lineCap: 'round',
                offsetX: 0,
                offsetY: 0,
                draggable: !this.activeSelect,
                lineJoin: 'round',
                points,
                id: 'shape_' + item.id,
            } as Konva.LineConfig;
        }) : [];
    }


    get selectLine(): Konva.LineConfig {
        return {
            stroke: 'black',
            fill: '#777',
            lineCap: 'round',
            lineJoin: 'round',
            strokeWidth: 0.5,
            points: [
                this.currentPoints[this.currentPoints.length - 1].x || 0,
                this.currentPoints[this.currentPoints.length - 1].y || 0,
                this.cursorX,
                this.cursorY,
            ],
        };
    }

    public mounted() {
        this.changeHistory = [];
        this.onItemDataChange();
        this.changeBackground();
        window.addEventListener('resize', () => {
            this.canvasWidth = window.innerWidth;
            this.canvasHeight = window.innerHeight;
        });
    }

    public changeBackground() {
        if (!this.itemData.background) {
            this.editView();
            return;
        }

        const background = new Image();

        this.loadBackground(this.itemData.background.url, background);
        // background.src = this.itemData.background.url;


        // this.backgroundLoading = true;
        background.onload = () => {

            this.backgroundLoading = false;
            this.canvasScale = this.canvasWidth / (background.width);
            this.background = background;
        };
    }

    get stage(): Konva.Stage {
        const stageComponent: any = this.$refs.stage;
        return stageComponent ? stageComponent.getStage() : null;
    }

    get shapesLayer(): Konva.Layer {
        const shapeComponent: any = this.$refs.shapesLayer;
        return shapeComponent ? shapeComponent.getNode() : null;
    }

    get stageConfig() {
        return {
            width: this.canvasWidth,
            height: this.canvasHeight,
            draggable: true,
            scale: {x: this.canvasScale, y: this.canvasScale},
        };
    }

    public undo() {
        this.selectedShapeIndex = -1;
        const itemData: VisualizationViewDataRequest = JSON.parse(this.changeHistory[this.changeHistoryIndex - 1]);
        itemData.areas.map((item) => shapeRefresh(item, this.shapesLayer));
        this.$store.commit(this.mutationsTypes.UPDATE_EDITED, itemData);
        this.changeHistoryIndex--;
    }

    public redo() {
        this.selectedShapeIndex = -1;
        const itemData: VisualizationViewDataRequest = JSON.parse(this.changeHistory[this.changeHistoryIndex + 1]);
        itemData.areas.map((item) => shapeRefresh(item, this.shapesLayer));
        this.shapesLayer.draw();
        this.$store.commit(this.mutationsTypes.UPDATE_EDITED, itemData);
        this.changeHistoryIndex++;
    }

    public onItemDataChange() {
        if (this.changeHistoryIndex + 1 < this.changeHistory.length) {
            this.changeHistory.splice(this.changeHistoryIndex + 1);
        }
        this.changeHistory.push(JSON.stringify(this.itemData));
        this.changeHistoryIndex++;
    }

    public loadBackground(url: string, img: HTMLImageElement) {
      this.backgroundLoading = true;

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            img.src = '';
            this.backgroundLoading = false;
            this.editView();
            return;
          }
          return response.blob();
        })
        .then(
          (blob) =>
            new Promise < string > ((callback) => {
              if (!blob) { return; }
              const reader = new FileReader();
              reader.onload = function() {
                callback(this.result as string);
              };
              reader.readAsDataURL(blob);
            })
        )
        .then((uri: string) => {
          img.src = uri;
        });
    }
}
