import {VisualizationAreaDataRequest} from '@/modules/visualizations/shared/requests/visualization-area-data-request';
import Konva from 'konva';

export const angle = (cx: number, cy: number, ex: number, ey: number) => {
    const dy = ey - cy;
    const dx = ex - cx;
    return Math.round(Math.atan2(dy, dx) * 180 / Math.PI);
};

export const pointsDistance = (firstPoint: { x?: number, y?: number }, secondPoint: { x?: number, y?: number }) => {
    return Math.sqrt(
        Math.pow((firstPoint.x || 0) - (secondPoint.x || 0), 2) +
        Math.pow((firstPoint.y || 0) - (secondPoint.y || 0), 2),
    );
};

export const pointsWithOffset = (points: Array<{ x: number, y: number }>, offsetX: number, offsetY: number) => {
    return points.map(({x, y}) => {
        return {
            x: x + offsetX,
            y: y + offsetY,
        };
    });
};

export const centroid = (points: Array<{ x: number, y: number }>) => {
    if (points.length > 0) {
        const first = points[0];
        const last = points[points.length - 1];
        if (first.x !== last.x || first.y !== last.y) {
            points.push(first);
        }
        let twiceArea = 0;
        let x = 0;
        let y = 0;
        const nPts = points.length;
        let p1;
        let p2;
        let f;
        for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
            p1 = points[i];
            p2 = points[j];
            f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
            twiceArea += f;
            x += (p1.x + p2.x - 2 * first.x) * f;
            y += (p1.y + p2.y - 2 * first.y) * f;
        }
        f = twiceArea * 3;
        return {
            x: x / f + first.x,
            y: y / f + first.y,
        };
    } else {
        return null;
    }
};

export const shapeRefresh = (item: VisualizationAreaDataRequest, shapesLayer: Konva.Layer) => {
    const shape = shapesLayer.findOne(`#shape_${item.id}`);
    if (shape) {
        shape.x(item.offsetX);
        shape.y(item.offsetY);
    } else {
        item.points.map((point) => {
            point.x += item.offsetX;
            point.y += item.offsetY;
            return point;
        });
        item.offsetX = 0;
        item.offsetY = 0;
    }
    return item;
};
