import {VisualizationArea} from '@/modules/visualizations/shared/models/visualization-area';
import {MediaFile} from '@/modules/media/shared/models/media-file';
import {VisualizationAreaDataRequest} from '@/modules/visualizations/shared/requests/visualization-area-data-request';

export class VisualizationViewDataRequest {
    public id: number = 0;
    public name: string = '';
    // tslint:disable-next-line:variable-name
    public visualization_id: number = 0;
    // tslint:disable-next-line:variable-name
    public investment_object_id?: number = 0;
    // tslint:disable-next-line:variable-name
    public target_type?: string;
    // tslint:disable-next-line:variable-name
    public media_file_id: number = 0;
    // tslint:disable-next-line:variable-name
    public area_color: string = '';
    // tslint:disable-next-line:variable-name
    public investment_id: number = 0;
    public areas: any[] = [];
    public background?: MediaFile;
}
