export class VisualizationAreaDataRequest {
    public id: number = 0;
    public name: string = '';
    public points: Array<{ x: number, y: number }> = [];
    public offsetX: number = 0;
    public offsetY: number = 0;
    // tslint:disable-next-line:variable-name
    public assignable_type: string = '';
    // tslint:disable-next-line:variable-name
    public assignable_id: string = '';
    // tslint:disable-next-line:variable-name
    public visualization_view_id = 0;
    // tslint:disable-next-line:variable-name
    public visualization_id = 0;
    public description: string = '';
}
