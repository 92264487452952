
import {Component} from 'vue-property-decorator';
import {visualizationsModule} from '@/modules/visualizations/shared/state/module';
import {VisualizationDataRequest} from '@/modules/visualizations/shared/requests/visualization-data-request';
import FormComponent from '@/shared/components/layout/form/form-component';
import FormActions from '@/shared/components/layout/form/form-actions.vue';
import {investmentsModule} from '@/modules/investments/shared/state/module';
import {SelectListItem} from '@/shared/models/select-list-item';
import { callSuper } from '@/shared/helpers';

@Component({
    components: {FormActions},
})
export default class VisualizationForm extends FormComponent<VisualizationDataRequest> {
    public actionsTypes = visualizationsModule.actionsTypes;
    public investmentsTypes = investmentsModule.actionsTypes;
    public store = this.$store.state.visualizationsState;
    public storeAction = this.actionsTypes.STORE_ITEM;
    public updateAction = this.actionsTypes.UPDATE_ITEM;
    public requestData = new VisualizationDataRequest();

    public created() {
        //
    }

    get formTitle(): string {
        return this.edit ? 'Edytuj wizualizację' : 'Dodaj wizualizację';
    }

    get investmentsList() {
        return this.$store.state.investmentsState.dataList;
    }

    public investmentChange(investmentId: number) {
        this.clearError('investment_id');
        if (!this.itemData.name) {
            this.itemData.name = `${this.investmentsList.find((item: SelectListItem) => item.id === investmentId).name} - Wizualizacja`;
        }
    }

    public initForm() {
        this.$store.dispatch(this.investmentsTypes.FETCH_DATA, {simple: true});
        this.form = this.$refs.createVisualizationForm;
    }
}
