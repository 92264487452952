var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',[_vm._v(" Dodaj nowy obszar ")]),_c('v-card-text',[_c('v-form',{ref:"createAreaForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitAndNext()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nazwa obszaru","rules":[_vm.formRules.required]},model:{value:(_vm.itemData.name),callback:function ($$v) {_vm.$set(_vm.itemData, "name", $$v)},expression:"itemData.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"item-disabled":_vm.itemDisabled,"label":"Typ docelowego elementu","items":_vm.areaTypes,"item-text":"name","rules":_vm.formRules.requiredSelect},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"selection",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}}]),model:{value:(_vm.itemData.assignable_type),callback:function ($$v) {_vm.$set(_vm.itemData, "assignable_type", $$v)},expression:"itemData.assignable_type"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.itemData.assignable_type !== 'view')?_c('v-select',{attrs:{"label":"Docelowy element","disabled":!_vm.itemData.assignable_type,"item-value":"id","item-text":"name","items":_vm.subjects,"rules":_vm.formRules.requiredSelect},model:{value:(_vm.itemData.assignable_id),callback:function ($$v) {_vm.$set(_vm.itemData, "assignable_id", $$v)},expression:"itemData.assignable_id"}}):_c('auto-complete-with-add-new',{attrs:{"state":_vm.store,"filters":{
                            visualization_id: _vm.view.visualization_id,
                        },"item-template":{
                            area_color: '#147BD1',
                            visualization_id: _vm.view.visualization_id,
                            investment_id: _vm.view.investment_id,
                        },"formComponent":_vm.ViewForm,"module":_vm.viewsModule,"label":"Docelowy element"},model:{value:(_vm.itemData.assignable_id),callback:function ($$v) {_vm.$set(_vm.itemData, "assignable_id", $$v)},expression:"itemData.assignable_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Opis"},model:{value:(_vm.itemData.description),callback:function ($$v) {_vm.$set(_vm.itemData, "description", $$v)},expression:"itemData.description"}})],1),_c('form-actions',{staticClass:"pa-5 pt-8",attrs:{"block":"","disabled":!_vm.valid,"edit":_vm.itemData.id > 0,"next":false},on:{"submited":_vm.submitForm,"canceled":function($event){return _vm.$emit('canceled')}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }