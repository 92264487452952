
import Component from 'vue-class-component';
import ListComponent from '@/shared/components/layout/list/list-component';
import { InvestmentObject } from '@/modules/investments/shared/models/investment-object';
import { InvestmentObjectDataRequest } from '@/modules/investments/shared/requests/investment-object-data-request';
import { ModuleProps } from '@/shared/state/template/module-props';
import ActionConfirmDialog from '@/shared/components/dialogs/action-confirm-dialog.vue';
import { visualizationViewsModule } from '@/modules/visualizations/shared/state/submodules';
import { VisualizationView } from '@/modules/visualizations/shared/models/visualization-view';
import ViewForm from '@/modules/visualizations/components/views/form.vue';
import ViewEditor from '@/modules/visualizations/components/views/editor.vue';
import { ModuleShowPayload } from '@/shared/state/template/module-payloads';
import { VisualizationArea } from '@/modules/visualizations/shared/models/visualization-area';
import { ViewsState } from '@/modules/visualizations/shared/state/state/views';
import { visualizationsModule } from '@/modules/visualizations/shared/state/module';
import PreventUnload from '@/shared/PreventUnload.vue';
import { VisualizationViewDataRequest } from '@/modules/visualizations/shared/requests/visualization-view-data-request';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { callSuper } from '@/shared/helpers';

@Component({
    props: {
        visualizationId: Number,
        investmentId: Number,
        value: Number,
    },
    components: {
        PreventUnload,
        ActionConfirmDialog,
        ViewForm,
        ViewEditor,
        TooltipBtn,
    },
})
export default class VisualizationViewsList extends ListComponent<InvestmentObject, InvestmentObjectDataRequest> {
    public headers: object[] = [];
    public visualizationId!: number;
    public actionsTypes = visualizationViewsModule.actionsTypes;
    public visualizationActions = visualizationsModule.actionsTypes;
    public visualizationMutations = visualizationsModule.mutationsTypes;
    public mutationTypes = visualizationViewsModule.mutationsTypes;
    public fetchAction: string = this.actionsTypes.FETCH_DATA;
    public removeAction: string = this.actionsTypes.REMOVE_ITEM;
    public props: ModuleProps = visualizationViewsModule.moduleProps;
    public value!: number;
    public store: ViewsState = this.$store.state.visualizationsState.viewsState;
    public visualizationsStore: ViewsState = this.$store.state.visualizationsState;

    get visualization() {
        return this.$store.state.visualizationsState.current;
    }

    get visualizationLoading() {
        return this.$store.state.visualizationsState.loadingItem;
    }

    get editedItemEditor() {
        return this.store.editedItemEditor;
    }

    set editedItemEditor(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITED_EDITOR, value);
    }

    get currentView() {
        return this.store.currentView;
    }

    set currentView(value) {
        this.$store.commit(this.mutationTypes.UPDATE_VIEW, value);
        this.$emit('input');
    }

    get editorDialog() {
        return this.store.editorDialog;
    }

    set editorDialog(value) {
        this.$store.commit(this.mutationTypes.UPDATE_EDITOR_DIALOG, value);
    }

    public created() {
        this.id = Number(this.visualizationId);
        this.fetchData();
    }

    public closeForm() {
        callSuper(this, 'closeForm');
        this.fetchData();
    }

    public closeEditor() {
        this.editorDialog = false;
        this.fetchData();
        this.$emit('reload');
    }

    public clearView() {
        this.currentView = 0;
        if (this.meta && this.meta.to - this.meta.from < 1 && this.page === this.last_page) {
            this.page--;
        }
        this.fetchData();
    }

    public viewSaved() {
        this.fetchData();
        this.editorDialog = false;
        this.$emit('reload');
    }

    public setAsDefault(item: VisualizationView) {
        this.$store
            .dispatch(this.visualizationActions.UPDATE_ITEM, {
                id: item.visualization_id,
                visualization_view_id: item.id,
            })
            .then((response) => {
                if (response.data) {
                    this.$store.commit(this.visualizationMutations.UPDATE_CURRENT, response.data);
                }
            });
    }

    public openEditor(item: VisualizationView) {
        this.editedItemEditor = item ? item : ({} as VisualizationViewDataRequest);
        this.editorDialog = true;
        this.$store
            .dispatch(this.actionsTypes.SHOW_ITEM, { id: item.id })
            .then((response: ModuleShowPayload<VisualizationView>) => {
                if (response.data) {
                    const areas = response.data.areas.map((area: VisualizationArea) => {
                        return {
                            ...area,
                            offsetX: 0,
                            offsetY: 0,
                        };
                    });
                    this.editedItemEditor = { ...response.data, areas };
                }
            });
    }
}
